/* smartphone and above */
body.viewpermission-view > .outer-wrapper,
body.viewpermission-jlu-ublibrarydb-readinternalfields > .outer-wrapper,
body.viewpermission-none > .outer-wrapper {
  display: grid;
  margin: 0 auto;
  // min-width: 98vh;
  min-height: 98vh;
  column-gap: $grid_gap;
  row-gap: $grid_gap;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: (60px - $grid_gap) (70px - $grid_gap) fit-content(1em) fit-content(1em) 1fr auto;
}
body > .outer-wrapper {
  grid-template-areas:
    "logo mobileactions"
    "sectionname sectionname"
    "portalmessages portalmessages"
    "lockstatus lockstatus"
    "content content"
    "colone colone"
    "coltwo coltwo"
    "breadcrumb breadcrumb"
    "footer footer";
}
body.viewpermission-view,
body.viewpermission-jlu-ublibrarydb-readinternalfields,
body.viewpermission-none {
  .outer-wrapper > header {
    display: contents;
    #portal-logo {
      grid-area: logo;
      height: 60px;
    }
    #section-name {
      grid-area: sectionname;
      height: 70px;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding-top: 0.6em;
      @include globalNavBG;
    }
    .mobileActions {
      grid-area: mobileactions;
      ul {
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        list-style-type: none;
        li {
          padding: 0 1em 0 0.5em;
          cursor: pointer;
          a {
            color: $colorTextDark !important;
          }
          svg {
            padding-bottom: 0.2rem;
            height: 1.6rem;
            width: auto;
          }
        }
        li:last-child {
          svg {
            padding-bottom: 0;
            height: 2.2rem;
            width: auto;
          }
        }
      }
    }
  }
  #global_statusmessage{
    grid-area: portalmessages;
  }
  #plone-lock-status{
    grid-area: lockstatus;
  }
  main {
    display: contents;
    #portal-column-content {
      grid-area: content;
      padding: 0.6em;
    }
    #portal-column-one {
      grid-area: colone;
      padding: 0 0.6em;
    }
    #portal-column-two {
      grid-area: coltwo;
      padding: 0 0.6em;
    }
  }
  footer {
    display: contents;
    #portal-breadcrumbs {
      grid-area: breadcrumb;
      font-size: 0.9rem;
      border-bottom: 0.1em solid #d5d5d5;
      padding: 0 $grid_gap;
      // padding-top: 2.6em;
      ol {
        margin-bottom: 0;
      }
    }
    #portal-footer-wrapper {
      grid-area: footer;
      font-size: 0.9rem;
      justify-self: center;
      padding: 0 $grid_gap;
      .portletWrapper {
        padding-left: 0;
      }
    }
  }
}

body.viewpermission-view,
body.viewpermission-jlu-ublibrarydb-readinternalfields,
body.viewpermission-none {
  .section-byline {
    padding-left: 6px;
  }

  font-size: $font-size-base + 0.2;
  #top-banner {
    display: none;
  }

  #sub-logo {
    display: none;
  }

  #section-name {
    font-size: 1.5em;
  }

  .portletNavigationTree {
    display: none;
  }

  #mobileNavigationWrapper {
    .offcanvas-body {
      padding-top: 0;
    }
  }

  #mobileNavigation {
    font-size: 1.2em;
    a {
      display: block;
      padding: 0.8em 0;
      border-bottom: 0.1em dotted #b2b2b2;
    }
  }

  #portal-searchbox {
    > form {
      display: flex;
    }
    border: 0 solid transparent;
    border-radius: 0;
    box-shadow: none;
    overflow: hidden;
  }
  #searchGadget {
    border: 1px solid $gray-300;
  }
  .searchButton {
    background-color: rgba(0, 0, 0, 0);
    border: 0 none;
    color: $colorTextDark;
    cursor: pointer;
    margin-left: -28px;
    // position: relative;
    // right: 27px;
    // top: 2px;
    font-size: 0.4em;
    // z-index: 99;
    svg {
      width: 17px;
      height: auto;
    }
  }
  #dlinks {
    display: none;
  }

  .offcanvas-header.bg-primary .offcanvas-title {
    color: white;
  }

  .offcanvas-sub-header {
    display: flex;
    justify-content: space-evenly;
    padding: 0.5em;
    a {
      color: white;
      text-decoration: none;
    }
  }

  #section-document-actions {
    display: none;
  }

  .eventDetails {
    display: table-row;
    width: auto;
    float: none;
    table {
      border-top: 0 solid transparent;
      border-bottom: 0.1em solid $grayLight;
      border: 0.1em solid $grayLight;
    }
  }

  /* XS / smartphone only */
  @media (max-width: 575px) {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      hyphens: auto;
    }
    #portal-globalnav {
      display: none !important;
    }
    .navTreeCurrentItem{
      font-weight: bold;
    }
    .cpttableless {
      width: 98% !important;
      margin-bottom: 1em;
      display: block;
      div.viewlet {
        margin-left: 0 !important;
      }
    }
    .cptable,
    .cprow,
    .cpcell {
      display: block !important;
    }

    #slider-container {
      width: 100vw;
      height: 220px;
      margin-left: -0.69rem;
      margin-right: -0.69rem;
      box-sizing: border-box;
    }
    #slider {
      width: 100vw !important;
      height: 100%;
      .slide {
        width: 96vw !important;
        height: 100% !important;

        img {
          object-fit: cover;
          height: 220px;
        }
        .slideroverlay {
          width: 100% !important;
          height: 90px !important;
          font-size: 0.75rem;
          > p{
            padding: 0.3em !important;
          }
        }
      }
    }
  }
}
